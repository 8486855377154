@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind screens;


body{
  background-color:#f9f9f9;
  height: 100%;
}
@font-face {
  font-family: 'Larsseit-Regular';
  src: url("./fonts/Larsseit.otf") format('truetype');
}
.scroll-lock{
  overflow: hidden;
}
@layer base {
.btn {
  @apply px-[9px] py-[17px] rounded-md text-base inline-flex justify-center text-center items-center shadow-[0px_1px_2px_1px_rgba(0,0,0,0.05)];
}
.btn-primary{
  @apply text-[#00303B] font-medium bg-[#38D989] border-inherit

}
.btn-primary:hover{
  @apply border-blue-400 shadow-[0px_1px_2px_1px_rgba(0,0,0,0.1)]

}
.btn-primary:focus{
  @apply outline-none border-blue-600
}
.btn-primary:active{
  @apply border-blue-600  transition ease-in-out duration-150
}
.btn-secondary{
  @apply bg-[#FFFFFF] text-custom-primary font-medium border-[#C4CBDD] shadow-[1px_1px_2px_1px_rgba(0,0,0,0.05)]

}
.btn-secondary:hover{
  @apply border-gray-100 shadow-[0px_1px_2px_1px_rgba(0,0,0,0.1)]

}
.btn-secondary:focus{
  @apply outline-none border-blue-200
}
.btn-secondary:active{
  @apply border-gray-300  transition ease-in-out duration-150
}
.btn-sm{
  @apply w-[114px] h-[38px] 
}
.btn-normal{
  @apply w-[132px] h-[38px]
}
.btn-lg{
  @apply w-[226px] h-[38px]
}
.btn-xl{
  @apply w-[464px] h-[38px]
}

} 
@media (max-width: 480px) {
  .down-arrow,.up-arrow {
    background-image: url(../public/down-arrow/down-arrow.png);
    background-repeat:  no-repeat;
    background-size: 20px 20px;
    background-position: -2px 0;
  }
  .up-arrow {
    transform: rotate(180deg);
    background-position: -2px -5px;
    transform: rotate(180deg);
  }
}
@media (max-width: 1024px) {
  .down-arrow,.up-arrow {
    background-image: url(../public/down-arrow/down-arrow@2x.png);
    background-repeat:  no-repeat;
    background-size:  20px 20px;
    background-position: -2px 0;
   }
   .up-arrow {
    transform: rotate(180deg);
    background-position: -2px -5px;
    transform: rotate(180deg);
  }
}
@media (max-width: 1280px) {
  .down-arrow, .up-arrow {
    background-image: url(../public/down-arrow/down-arrow@3x.png);
    background-repeat:  no-repeat;
    background-size:  20px 20px;
    background-position: -2px 0px;
   }
  .up-arrow {
    transform: rotate(180deg);
    background-position: -2px -5px;
    transform: rotate(180deg);
  }
}

.circle {
  position: relative;
  background: #FFFFFF;
  border-radius: 50%;
  width: 20px;
  height: 20px;
}
.checkmark {
  position: absolute;
  transform: rotate(45deg) translate(-50%, -50%);
  left: 25%;
  top: 45%;
  height:50%;
  width: 25%;
  border-bottom: 2px solid white;
  border-right: 2px solid white;
}
.breadcrumb-not-active {
  color: #cccccc;
  }
  
  .breadcrumb-active {
  color: #000000;
  }
 
  .css-clock {
    width: 100%;
    padding: 2px;
    height: 100%;
    border-radius: 50%;
    background-color: #4839D9;
    position: relative;
    display: block;
    
 }
  .css-clock::before {
    content: "";
    height: 7px;
    width: 3.5px;
    background-color: white;
    display: block;
    position: absolute;
    left: 8px;
    top: 4px;
    opacity: 2;
    
 }
  .css-clock::after {
    content: "";
    height: 7px;
    width: 3px;
    background-color: white;
    display: block;
    position: absolute;
    top: 8px;
    left: 11px;
    transform: rotate(112deg);
    opacity: 1;
 }

.play {
  width: 25px;
  height: 25px;
  background: #34D399;
  border: none;
  border-radius: 100%;
  margin: auto;
  cursor: pointer;
}


.play::after {
  content: '';
  display: inline-block;
  position: relative;
  top: 3px;
  left: 10px;
  border-radius:30%; 
  border-style: solid;
  border-width: 8px 0 8px 9px;
  border-color: transparent transparent transparent white;
}
.arrow {
  border: solid grey;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  }
  .droparrow {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  }
  nav a:hover{
    color: #f9f9f9;
  }