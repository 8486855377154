@tailwind base;

@layer base {
  /* Typography */
  .fs--1 {
    @apply text-[.83333rem]
  }

  .auth * {
    @apply font-sans;
  };

  .auth * {
    .h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
      @apply leading-[1.2] text-[#344050];
    };

    .h5, h5 {
      @apply mb-1 text-[1.2rem];
    };

    .h4, h4 {
      @apply text-[1.44rem] text-[#344050] font-medium
    }

    .a, a {
      @apply text-[#01a3ff] no-underline bg-transparent;
    };

    .a:hover, a:hover,
    .a:active, a:active {
      @apply !text-[#2c7be5]
    }

    .a:hover, a:hover {
      @apply underline
    }

    .a:visited, a:visited {
      @apply text-[#3a4777]
    }
  };

  /* Layout */
  .auth .container {
    @apply w-full mx-auto px-[15px]
  }

  /* Buttons */
  .btn-auth {
    @apply bg-[#000000] text-base text-center px-4 py-[0.3125rem] border border-solid rounded select-none;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out
  }

  .btn-auth.disabled, .btn-auth:disabled {
    @apply cursor-not-allowed opacity-60
  }

  .btn-auth:hover {
    @apply no-underline
  }

  .btn-auth-primary, .btn-auth-primary[type='button'],
  .btn-auth-primary.disabled,
  .btn-auth-primary:disabled {
    @apply text-white bg-[#00303B] border-[#00303B]
  }

  .btn-auth-primary:hover {
    @apply bg-[#001115] border-[#000708]
  }

  .btn-auth-sm {
    @apply w-auto py-[.1875rem] px-[.75rem] text-sm rounded-[.2rem]
  }

  /* Inputs */
  .form-row {
    @apply flex flex-wrap mx-[-5px]
  }

  .form-control {
    @apply bg-white text-[#344050] px-4 py-[0.3125rem] h-[calc(1.5em+0.625rem+2px)] border border-solid border-[#d8e2ef] rounded font-light;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  }

  .form-control::placeholder {
    @apply text-[#b6c1d2] font-light
  }

  .form-control:focus,
  .form-control:focus-visible {
    @apply border-[#0098bb] outline-0;
    box-shadow: 0 0 0 0.2rem rgba(0,48,59,.25);
  }

  .auth .form-label {
    @apply leading-[1.45rem] tracking-[.02em]
  }

  /* Checkbox */
  .auth-checkbox {
    @apply relative invisible
  }

  .auth-checkbox::before,
  .auth-checkbox::after {
    @apply block absolute content-[''] top-[-1px] left-[-1px]  w-4 h-4 visible
  }

  .auth-checkbox::before {
    @apply bg-white border border-[1px] border-[#9da9bb] rounded;
    transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  }

  .auth-checkbox::after {
    background: 50%/50% 50% no-repeat;
  }

  .auth-checkbox:checked.auth-checkbox::before {
    @apply text-white border-[#00303b] bg-[#00303b]
  }

  .auth-checkbox:focus.auth-checkbox::before {
    box-shadow: 0 0 0 0.2rem rgba(0,48,59,.25);
  }

  .auth-checkbox:focus:not(:checked).auth-checkbox::before {
    @apply border-[#0098bb]
  }

  .auth-checkbox:checked.auth-checkbox::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
  }

  /* Animations */
  @keyframes fadeInElement {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes fadeInElementDelay {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes fadeInCard {
    0% {
      top: 50px;
      opacity: 0;
    }
    100% {
      top: 0px;
      opacity: 1;
    }
  }

  .auth .card {
    animation: .8s ease-in-out 0s normal forwards 1 fadeInCard
  }

  .auth .logo {
    animation: .5s ease-in-out 0s normal forwards 1 fadeInElement
  }

  .auth .form-support {
    opacity: 0;
    animation: 2s ease-in-out 0s normal forwards 1 fadeInElementDelay;
  }

  /* Responsive */
  @media (min-width: 1540px) {
    .auth .container {
      max-width: 1480px;
    }
  }

  @media (min-width: 1200px) and (max-width: 1539px) {
    .auth .container {
      max-width: 1140px;
    }

    .auth .col-xxl-4 {
      width: 41.66666667%;
    }
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    .auth .container {
      max-width: 960px;
    }
  }

  @media (min-width: 768px) and (max-width: 991px) {
    .auth .container {
      max-width: 720px;
    }
  }

  @media (min-width: 576px) and (max-width: 767px) {
    .auth .container {
      max-width: 540px;
    }
  }
}